.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading,
.title {
  margin-bottom: 15px;
}
.review_heading {
  margin-bottom: var(--container-padding);
}
.title {
  text-align: center;
}
.wrapper {
  width: 100%;
  padding: 0 25px;
}
.media_wrapper {
  max-height: 500px;
}
.media_wrapper video,
.media_wrapper img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.block_wrapper {
  padding: 10px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.text p,
.text ul,
.text ol {
  margin-bottom: var(--container-margin);
}
.reviews_wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--container-margin);
}
.review_rating {
  display: flex;
  align-items: center;
  gap: 15px;
}
.review_text {
  margin-bottom: var(--container-margin);
}
.review_text p,
.review_text ul,
.review_text ol {
  margin-bottom: var(--container-padding);
}
@media screen and (max-width: 980px) {
  .wrapper {
    padding: 0 10px;
  }
  .block_wrapper {
    padding: 0;
    display: block;
  }
  .media_wrapper {
    max-height: 100%;
    margin-bottom: var(--container-margin);
  }
  .heading,
  .title {
    margin-bottom: var(--container-margin);
  }
  .reviews_wrapper {
    margin-bottom: var(--container-padding);
  }
}
