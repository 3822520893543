.container {
  padding: var(--container-padding) 10%;
}

.image_container {
  display: flex;
}

.image_wrapper {
  flex: 1 0 auto;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 var(--container-margin);
}
.text {
  color: var(--text-color);
  flex: 3 0 auto;
  padding: var(--container-padding);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text p,
.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text li {
  margin-bottom: var(--container-margin);
}
@media screen and (max-width: 980px) {
  .container {
    padding: var(--container-padding);
  }
  .image_wrapper {
    flex: 0 0 40%;
    border-radius: 50%;
    margin: 0;
  }
  .image_container {
    justify-content: center;
  }
  .text {
    flex: 0 0 50%;
  }
  .text p,
  .text h1,
  .text h2,
  .text h3,
  .text h4,
  .text h5,
  .text li {
    margin-bottom: var(--container-margin-mobile);
    font-size: var(--xl);
  }
}
