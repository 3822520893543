.timeline
  display: flex
  gap: 2rem
  padding-left: 18pt
  padding-right: 18pt
  margin-bottom: var(--container-margin)
  background-color: #def1fa
  padding-top: 4rem
  padding-bottom: 4rem

.container
    display: flex
    max-width: 1000px
    margin: 0 auto

.column
  display: flex
  flex-direction: column
  gap: 3rem
  padding-left: 18pt
  padding-right: 18pt
  margin-bottom: var(--container-margin)
