.container {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.heading {
  color: var(--heading-color);
  font-size: var(--xl3);
  line-height: 28px;
}
.text {
  color: var(--text-color);
  line-height: 20px;
}

@media screen and (max-width: 980px) {
  .heading {
    font-size: var(--xl2);
  }
  .container {
    padding: 0 var(--container-padding);
  }
}
