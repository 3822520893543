.container {
  background-color: var(--background-color);
  flex: 1 1 0px;
  padding: var(--container-padding) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.container p {
  margin: 2px 0;
  font-size: var(--sm);
}
.user_name {
  color: var(--user-name-color);
}
.user_name p {
  font-size: var(--xl);
  font-weight: var(--text-weight-bold);
  text-align: center;
}
.icon_wrapper {
  flex: 0 0 45%;
  justify-content: center;
  white-space: nowrap;
  padding-left: 5px;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 5px;
}
.verified_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0px;
}
.verified_icon {
  color: var(--verified-color);
}
.verified_container p {
  color: var(--verified-color);
  font-weight: var(--text-weight-black);
  font-size: var(--sm);
  margin: 0;
  margin-left: 10px !important;
}
.img_wrapper {
  width: 250px !important;
  margin: 0 auto;
  margin-bottom: 24px;
  border-radius: 50%;
  overflow: hidden;
}
.review_body {
  color: var(--text-color);
  margin-bottom: 18px;
}
.review_body p {
  font-size: var(--md);
  text-align: center;
  padding: 0 24px;
}
.review_body h5 {
  color: var(--heading-color);
  text-align: center;
  margin: 0;
  margin-bottom: 5px;
}
@media screen and (max-width: 980px) {
  .container {
    border-radius: 10px;
    box-shadow: none;
    padding: var(--container-padding);
    margin: 0 var(--container-margin-mobile);
    height: 100%;
  }
  .container p {
    padding: 0;
  }
  .icon_wrapper {
    font-size: var(--lg);
    flex: 0 0 30%;
  }
  .user_name {
    margin-bottom: 10px;
  }
  .user_name p {
    font-size: var(--xl2);
    margin: 0;
  }
  .review_body {
    padding: 0 var(--container-padding);
  }
}
