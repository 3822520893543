.open {
  height: auto;
  padding-bottom: 24px;
}
.closed {
  height: 0;
}
.container {
  margin: 0 auto;
  margin-top: calc(var(--container-margin) * -1);
  color: var(--text-color);
  overflow: hidden;
  width: 100%;
}
.expanding_btn {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 20px 0;
}
.heading {
  margin: 0;
  display: flex;
  text-align: left;
}
.container header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container header h1 {
  font-size: var(--lg);
  font-weight: var(--text-weight-bold);
}
.contents {
  border-bottom: 1px solid rgba(94, 87, 80, 0.5);
}
.contents h1 {
  font-size: var(--lg);
}
.contents p,
.contents a,
.contents li {
  font-size: var(--md);
}
.dropdown_icon {
  font-size: 15px;
  margin-right: 20px;
}
.contents a {
  color: var(--link-text-color);
}
@media screen and (max-width: 980px) {
  .container {
    width: 100%;
  }
  .container p {
    padding-right: 0;
  }
  .closed {
    height: 0;
  }
}
