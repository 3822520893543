.container {
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 24px;
}
.user_name {
  display: flex;
  margin-bottom: 5px;
}
.user_name p {
  color: var(--user-name-color);
  font-weight: var(--text-weight-bold);
}
.stars {
  font-size: var(--md);
  margin-right: 12px;
  display: flex;
  align-items: center;
}
.text {
  color: var(--text-color);
}
.text p {
  font-size: var(--md);
}
@media screen and (max-width: 980px) {
  .container {
    padding: 14px;
  }
  .user_name p {
    font-size: var(--md);
  }
}
