.container {
  position: relative;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-color);
  background-color: var(--background-color);
  overflow: hidden;
}
.bundle_banner {
  background-color: var(--heading-background-color);
  text-align: center;
  position: absolute;
  width: 200px;
  top: 38px;
  right: -55px;
  transform: rotate(45deg);
}
.bundle_banner h3 {
  color: var(--heading-color);
  font-weight: var(--heading-weight-normal);
  font-size: var(--md);
  line-height: 26px;
}
.product_detail_container {
  display: flex;
  padding: 12px;
  padding-bottom: 0;
  width: 100%;
  align-items: center;
}
.product_image_wrapper {
  min-width: 120px;
  height: auto;
  margin-left: 8px;
}
.product_image_wrapper img {
  padding-top: 0 !important;
}
.product_image_wrapper img {
  width: 100% !important;
  height: 80px !important;
  position: relative !important;
  object-fit: contain;
}
.price_button_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}
.product_btn {
  width: 100%;
  height: 65px;
  margin: 0 auto;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  border-radius: 5px;
  color: var(--button-text-color);
  font-weight: var(--text-weight-bold);
  font-size: var(--xl);
  padding: var(--container-padding) 0;
  cursor: pointer;
}
.product_btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}
.price_wrapper {
  font-weight: var(--text-weight-black);
}
.bundle_name_items_wrapper {
  padding: 0 var(--container-padding);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.product_name {
  margin: 0;
  margin-bottom: 4px;
  color: var(--product-name-color);
  font-weight: var(--text-weight-bold);
  width: 90%;
}
.product_name,
.price_wrapper {
  font-size: var(--lg);
}
.compare_price {
  color: var(--compare-at-color);
  text-decoration: line-through;
  font-weight: var(--text-weight-normal);
}
.savings {
  font-weight: var(--text-weight-bold);
}
.compare_price,
.savings {
  font-size: var(--md);
}
@media screen and (max-width: 980px) {
  .product_detail_container {
    padding-bottom: var(--container-padding);
  }
  .product_image_wrapper {
    width: 70px;
    margin-right: 8px;
    max-height: 95px;
  }
  .bundle_detail_wrapper p,
  .bundle_detail_wrapper li {
    padding-left: 0;
    font-size: var(--md);
  }
  .bundle_detail_wrapper ul {
    padding-left: var(--container-padding);
  }
  .price_button_wrapper {
    margin: 0;
  }
  .product_btn {
    margin: 0;
    font-size: var(--md);
    height: 55px;
  }
  .bundle_banner {
    right: -65px;
    top: 24px;
  }
  .bundle_banner h3 {
    font-size: var(--sm);
  }
  .bundle_name_items_wrapper {
    gap: 0;
  }
  .product_image_wrapper {
    min-width: auto;
  }
  .product_image_wrapper img {
    height: 100px !important;
  }
}
