.container {
  max-width: 800px;
  margin: 0 auto;
}
.heading {
  margin-bottom: 30px;
  font-size: var(--xl3);
}
.wrapper p {
  margin-bottom: 30px;
}
.image_wrapper {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
.image_wrapper img {
  object-fit: contain !important;
  min-width: 90% !important;
}
.text p {
  font-size: var(--xl);
  line-height: 20px;
  letter-spacing: var(--letter-spacing);
}
.video_wrapper {
  max-width: 600px;
  margin: 0 auto;
}
.text h1 {
  font-size: var(--xl3);
}
@media screen and (max-width: 980px) {
  .container {
    width: 100%;
    padding: 0 var(--container-padding);
  }
  .image_wrapper img {
    object-fit: cover !important;
    width: 100% !important;
  }
  .video_wrapper {
    max-width: auto;
    width: 100%;
  }
}
