.container {
  padding: var(--container-padding) 10%;
}
.box_wrapper {
  margin: 0 auto;
  background-color: var(--background-color);
  padding: var(--container-padding);
  color: var(--text-color);
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow-all);
}
.offer_price_name {
  flex: 0 0 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--container-padding);
}
.product_name {
  flex: 0 0 70%;
}
.price_wrapper {
  text-align: right;
}
.price_wrapper h2,
.price_wrapper h3 {
  margin: 0;
}
.button_wrapper {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--container-padding) 0;
  gap: var(--container-padding);
}

@media screen and (max-width: 980px) {
  .container {
    padding: 0;
  }
  .box_wrapper {
    flex-direction: column;
    width: 100%;
  }
  .offer_price_name {
    padding: 0;
    width: 100%;
  }
  .offer_price_name h2 {
    font-size: var(--xl2);
  }
  .button_wrapper {
    width: 100%;
  }
}
