.container {
  padding: var(--container-padding) 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  color: var(--button-text-color);
  background-color: var(--button-color);
  border: 1px solid var(--button-border-color);
  width: 80%;
  text-align: center;
  padding: var(--container-padding) 0;
}

.button:hover {
  color: var(--button-hover-text-color);
  background-color: var(--button-hover-color);
  border-color: var(--button-hover-border-color);
}

.button p {
  font-size: var(--xl4);
}

@media screen and (max-width: 980px) {
  .container {
    padding: var(--container-padding);
  }
  .button {
    width: 100%;
  }
}
