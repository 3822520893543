.box_item {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
}
.heading {
  text-align: left;
  color: var(--heading-color);
  margin-bottom: 24px;
  line-height: 24px;
  font-size: var(--xl);
}
.text {
  text-align: left;
  margin-bottom: 24px;
}
.text p,
.text a,
.text li {
  font-size: var(--md);
}

.media_container {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: var(--container-margin);
  height: 400px;
}
.video_caption {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top-right-radius: 8px;
  color: var(--caption-color);
  background-color: var(--caption-background-color);
  font-weight: var(--text-weight-bold);
  padding: var(--container-padding) var(--container-padding);
  letter-spacing: 0.4px;
  z-index: var(--z-index-overlay);
}
.media_container video {
  height: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image_wrapper,
.image_wrapper > div {
  height: 100%;
}
.bullet_container {
  display: flex;
  flex-direction: column;
  gap: var(--container-padding);
}
@media screen and (max-width: 980px) {
  .heading {
    margin-bottom: var(--container-margin-mobile);
  }
  .box_item p {
    margin-bottom: 0;
  }
  .media_container {
    margin-bottom: 16px;
    height: 300px;
  }
  .text {
    margin-bottom: 15px;
  }
}
