.container {
  background-color: var(--background-color);
  scroll-margin-top: 60px;
}
.value_proposition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  width: 100%;
  max-width: 1180px;
  padding: 0 25px;
  margin: 0 auto;
}
.top_bottom_padding {
  padding-bottom: 60px;
  padding-top: 60px;
  scroll-margin-top: 0;
}
.tagline {
  color: var(--tagline-color);
  font-size: var(--xl2);
  font-weight: var(--text-weight-bold);
  letter-spacing: var(--tagline-letter-spacing);
}
.heading {
  color: var(--heading-color);
  font-size: var(--xl5);
}
.heading,
.text {
  text-align: center;
  max-width: 800px;
  margin-bottom: var(--container-margin);
}
.text {
  line-height: 24px;
}
.sec_wrapper {
  display: flex;
  width: 85%;
  gap: 48px;
  flex-wrap: wrap;
}
.item_wrapper {
  flex: 1 1 0;
  position: relative;
}
.divider_text {
  position: absolute;
  background-color: var(--divider-background-color);
  color: var(--divider-text-color);
  padding: var(--container-padding);
  border-radius: 50%;
  align-self: center;
  justify-self: center;
  max-width: 75px;
  max-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  top: 150px;
  right: -61px;
  z-index: var(--z-index-overlay);
  line-height: 35px;
}
.divider_text p {
  padding: 8px;
  font-size: var(--xl3);
  font-weight: var(--text-weight-bold);
}
@media screen and (max-width: 980px) {
  .value_proposition {
    padding: 0 14px;
  }
  .heading {
    text-align: center;
    font-size: var(--xl4);
  }
  .heading {
    margin-bottom: 24px;
  }
  .text p,
  .text a,
  .text li {
    font-size: var(--xl);
  }
  .sec_wrapper {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-top: 0;
    gap: var(--container-margin);
  }
  .divider_text {
    display: none;
  }
  .top_bottom_padding {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .tagline {
    font-size: var(--lg);
    margin-bottom: var(--container-margin-mobile);
  }
}
