.nav_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1180px;
  margin: 0 auto;
  padding: 15px 25px;
}
.logo_wrapper img {
  position: relative !important;
  width: auto !important;
  height: 70px !important;
  object-fit: contain;
  padding-top: 0 !important;
}
.links {
  display: flex;
  align-items: center;
}
.links a {
  padding: 20px;
  letter-spacing: 1px;
  font-weight: var(--text-weight-bold);
  font-size: var(--md);
  white-space: nowrap;
}
@media screen and (max-width: 980px) {
  .hide_on_mobile {
    display: none;
  }
  /* Do not display links on mobile */
  .links {
    display: none;
  }
  .nav_wrapper {
    justify-content: center;
  }
}
