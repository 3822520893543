.banner {
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  position: relative;
  z-index: var(--z-index-overlay);
  box-shadow: var(--box-shadow-corner);
  padding: var(--container-padding) 0;
}
.banner h4 {
  margin: 0;
  font-weight: var(--heading-weight-normal);
  color: var(--text-color);
  text-align: center;
}

@media screen and (max-width: 980px) {
  .banner h4 {
    font-size: var(--md);
  }
}
