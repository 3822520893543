.timelineItem 
    display: flex

.timelineItem .content
    background-color: var(--background-color)
    border: 2px solid var(--text-color-dark)
    padding: 20px
    border-radius: 5px
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
    display: flex
    flex-direction: column
    gap: 6px
    min-width: 700px
    height: auto
    