.container {
  padding: var(--container-padding) 0;
}

.images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.image_wrapper {
  flex: 1 1 0px;
  align-self: stretch;
}

@media screen and (max-width: 980px) {
  .image_wrapper {
    flex: 0 0 50%;
  }
}
