.container {
  padding: var(--container-padding) 0;
  background-color: var(--background-color);
  scroll-margin-top: 60px;
}
.top_bottom_padding {
  padding-top: 60px;
  padding-bottom: 60px;
  scroll-margin-top: 0;
}
.three_box_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 1180px;
  width: 100%;
  padding: 0 25px;
  margin: 0 auto;
}
.process_vd {
  color: var(--text-color);
  width: 100%;
}
.process_vd h1 {
  color: var(--heading-color);
}
.tagline {
  font-size: var(--xl2);
  font-weight: var(--text-weight-bold);
  padding-bottom: 5px;
  letter-spacing: var(--tagline-letter-spacing);
}
.heading,
.text {
  margin: 0 auto;
  text-align: center;
  max-width: 800px;
}
.text,
.process_vd h1 {
  margin-bottom: var(--container-margin);
}
.boxes_container {
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}
@media screen and (max-width: 980px) {
  .sec_wrapper {
    width: 100%;
    padding: 0;
  }
  .heading {
    padding: 0 var(--container-padding);
    font-size: var(--xl4);
    max-width: 300px;
  }
  .three_box_section {
    padding: 0 14px;
  }
  .boxes_container {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .text {
    padding: 0 var(--container-padding);
  }
  .tagline,
  .text p {
    font-size: var(--lg);
  }
  .process_vd h1 {
    margin-bottom: 24px;
  }
  .top_bottom_padding {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
