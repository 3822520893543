.container {
  padding-bottom: var(--container-padding);
}
.header {
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  position: relative;
  z-index: var(--z-index-overlay);
  box-shadow: var(--box-shadow-corner);
  padding: var(--container-padding) 0;
}
[data-page-type='two-column'] .header {
  box-shadow: none;
  padding: 0;
}
.logo {
  display: flex;
  align-items: center;
  width: 100%;
}
.side_aligned_items {
  justify-content: space-between;
}
.centered_item {
  justify-content: center;
}
.img_wrapper {
  flex: 0 0 150px;
  align-self: center;
}
@media screen and (max-width: 980px) {
  .container {
    padding: 0;
    margin-bottom: calc(var(--container-margin-mobile) * -1);
  }
  .img_wrapper {
    flex: 0 0 80px;
  }
}
