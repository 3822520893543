.container {
  padding: var(--container-padding) 0;
  background-color: var(--background-color);
  scroll-margin-top: 60px;
}
.top_bottom_padding {
  padding-top: 60px;
  padding-bottom: 60px;
  scroll-margin-top: 0;
}
.testimonial_section {
  color: var(--text-color);
}
.sec_wrapper {
  margin: 0 auto;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  max-width: 1180px;
}
.tagline {
  color: var(--tagline-color);
  font-weight: var(--text-weight-black);
  font-family: var(--text-font-family);
  letter-spacing: var(--tagline-letter-spacing);
  text-align: center;
  margin-bottom: 10px;
}
.testimonial_heading {
  text-align: center;
  color: var(--heading-color);
  margin: 0 auto;
  margin-bottom: var(--container-margin);
  max-width: 800px;
}
.testimonial_text {
  text-align: center;
  margin: 0 auto;
  margin-bottom: var(--container-margin);
  max-width: 800px;
}
.ratings_wrapper {
  background-color: var(--ratings-background-color);
  box-shadow: var(--box-shadow-all);
  border-radius: 8px;
  padding: var(--container-padding) 24px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: var(--container-margin);
}
.testimonial_social_wrapper {
  width: 100%;
}
@media screen and (max-width: 980px) {
  .sec_wrapper {
    width: 100%;
    padding: 0 14px;
  }
  .tagline {
    font-size: var(--md);
  }
  .testimonial_heading {
    padding: 0 var(--container-padding);
    font-size: var(--xl4);
  }
  .testimonial_text {
    padding: 0 var(--container-padding);
  }
  .testimonial_text p {
    font-size: var(--lg);
  }
  .ratings_wrapper {
    padding: var(--container-padding);
  }
  .carousel_item_wrapper {
    height: 100%;
  }
  .top_bottom_padding {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
