.container {
  background-color: var(--background-color);
  scroll-margin-top: 60px;
}
.items_wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--container-margin);
}
.sec_wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  padding: var(--container-padding) 0;
}
.top_bottom_padding {
  padding-top: 60px;
  padding-bottom: 60px;
  scroll-margin-top: 0;
}
.heading {
  margin: 0;
  font-weight: var(--heading-weight-bold);
  text-align: center;
  margin-bottom: var(--container-margin);
  color: var(--heading-color);
}
.tagline {
  color: var(--tagline-color);
  font-weight: var(--text-weight-black);
  font-family: var(--text-font-family);
  letter-spacing: var(--tagline-letter-spacing);
  text-align: center;
}
.text {
  margin: 0;
  color: var(--text-color);
  text-align: center;
  font-family: var(--text-font-family);
  margin-bottom: var(--container-margin);
}
.text a,
.text p,
.text li{
  margin-bottom: var(--container-margin);
}
@media screen and (max-width: 980px) {
  .container {
    width: 93%;
    margin: 0 auto;
    padding: var(--container-padding) 0;
  }
  .heading {
    padding: 0 var(--container-padding);
    font-size: var(--xl4);
  }
  .top_bottom_padding {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
