.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media screen and (max-width: 980px) {
  .container {
    padding: 0 var(--container-padding);
  }
}
