.main {
  background-color: var(--background-color);
  padding: var(--container-padding) 0;
  position: relative;
  width: 100%;
}
.banner_sets_wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 140px;
}
.container {
  display: flex;
  animation: rotate 100s linear infinite;
  height: 100%;
}
.overlay_text {
  color: var(--text-color);
  text-shadow: 2px 4px 3px rgb(0 0 0 / 30%);
  position: absolute;
  top: 0;
  z-index: var(--z-index-overlay);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-size: var(--xl5);
  font-weight: var(--text-weight-bold);
  line-height: 26px;
}
.images_wrapper {
  display: flex;
  flex: 1 1 0;
  height: 100%;
  align-items: center;
}
.image_wrapper img {
  padding-top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.image_wrapper {
  flex: 1 1 0;
  width: 140px;
  height: 100%;
  margin: 0 3px;
  border-radius: 10px !important;
  overflow: hidden !important;
}
.image_wrapper img {
  object-fit: cover;
}

@media screen and (max-width: 980px) {
  .overlay_text {
    font-size: var(--xl3);
    text-align: center;
  }
}

@keyframes rotate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
