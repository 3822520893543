.box_item {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
}
.tagline {
  color: var(--tagline-color);
  background-color: var(--tagline-background-color);
  font-size: var(--xl);
  font-weight: var(--text-weight-bold);
  text-align: left;
  letter-spacing: var(--tagline-letter-spacing);
  margin-bottom: 5px;
}
.fancy_tagline {
  width: fit-content;
  padding: 6px var(--container-padding);
  letter-spacing: 0.4px;
  border-radius: 8px;
  line-height: 25px;
  margin-bottom: 20px;
}
.heading {
  text-align: left;
  color: var(--heading-color);
  margin-bottom: 16px;
}
.text {
  text-align: left;
  margin-bottom: 15px;
}
.text p,
.text a,
.text li {
  font-size: var(--md);
  line-height: 24px;
}
.tagline + div p,
.tagline + div a,
.tagline + div li {
  font-size: var(--xl);
}
.video_container {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 24px;
  height: 400px;
}
.box_item video {
  height: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bullet_container {
  display: flex;
  flex-direction: column;
  gap: var(--container-padding);
}
.bullet_container p {
  text-align: left !important;
}
.media_container {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: var(--container-margin);
  height: 400px;
}
.image_wrapper,
.image_wrapper > img {
  height: auto !important;
  width: 100% !important;
}
.media_container img {
  object-fit: cover;
}
@media screen and (max-width: 980px) {
  .heading {
    margin-bottom: var(--container-margin-mobile);
  }
  .box_item p {
    margin-bottom: 0;
  }
  .media_container {
    margin-bottom: 16px;
    height: 300px;
  }
  .fancy_tagline {
    margin: 0 auto 16px auto;
  }
  .fancy_tagline ~ div p,
  .fancy_tagline ~ div a,
  .fancy_tagline ~ div li {
    text-align: center;
    font-size: var(--lg);
  }
}
