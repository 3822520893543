.section {
  background-color: var(--background-color);
  box-shadow: var(--box-shadow-all);
  position: sticky;
  z-index: var(--z-index-sticky);
  top: 0;
  height: 64px;
  transition: transform 0.3s;
}
.section a {
  text-align: center;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1180px;
  margin: 0 auto;
  height: 100%;
  padding: 0 25px;
}
.column {
  display: flex;
  align-items: center;
}
.btn {
  width: 100%;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border-radius: 5px;
  border: 1px solid var(--button-border-color);
  font-weight: var(--text-weight-bold);
  display: flex;
  align-items: center;
  max-width: 150px;
  position: relative;
  padding: 7px 0 4px 0;
  font-size: var(--lg);
  justify-content: center;
}
.btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}
.text p {
  margin-top: 0;
  font-size: var(--xl);
  font-weight: var(--text-weight-normal);
  color: var(--text-color);
}
.image_wrapper {
  margin-right: var(--container-padding);
  width: 22px;
}
@media screen and (max-width: 980px) {
  .section {
    height: auto;
    padding: var(--container-padding) 0;
  }
  .show {
    transform: translate(0, 0) !important;
  }
  .hide {
    transform: translate(0, -100%);
  }
  .container {
    padding: 0 14px;
  }
  .section a {
    max-width: 120px;
  }
  .column {
    align-items: center;
  }
  .btn {
    width: 100%;
    margin-bottom: 0;
    font-size: var(--sm);
  }
  .text p {
    margin: 0 var(--container-margin-mobile);
    margin-bottom: 0;
    font-size: var(--sm);
    text-align: left;
  }
  .image_wrapper {
    width: 30px;
    margin-right: 0;
  }
}
