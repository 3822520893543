.review {
  display: flex;
  flex-direction: row;
  padding: var(--container-padding) 0;
}
.review_text_wrapper {
  flex: 6 6 0;
}
.name_wrapper {
  display: flex;
  align-items: center;
}
.reviewer_name {
  font-weight: var(--text-weight-black);
  font-size: var(--lg);
  margin-left: 5px;
}
.verified_container p {
  color: var(--verified-color);
  font-weight: var(--text-weight-black);
  font-size: var(--sm);
  margin: 0;
  margin-left: 10px !important;
}
.verified_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.verified_icon {
  color: var(--verified-color);
}
.review_detail {
  padding-top: 10px;
}
.review_detail p {
  font-size: var(--md);
  font-weight: var(--text-weight-normal);
}
.user_img_wrapper {
  flex: 1 1 0;
  margin-right: 10px;
}
.user_img_wrapper img {
  border-radius: 50%;
  max-width: 80px !important;
  max-height: 80px;
}
.review_header {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 980px) {
  .verified_container p {
    font-size: var(--xs);
  }
  .reviewer_name {
    font-size: var(--md);
  }
  .review_detail p {
    font-size: var(--sm);
  }
}
