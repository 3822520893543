.container {
  padding: var(--container-padding) 10%;
}

.heading {
  color: var(--heading-color);
  margin-bottom: var(--container-margin);
  text-align: center;
}

.image_container {
  width: 100%;
  margin-bottom: var(--container-margin);
}

.text {
  color: var(--text-color);
}

.text p,
.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text li {
  margin-bottom: var(--container-margin);
}

@media screen and (max-width: 980px) {
  .container {
    padding: var(--container-padding);
  }
  .heading {
    font-size: var(--xl4);
  }
  .text p,
  .text h1,
  .text h2,
  .text h3,
  .text h4,
  .text h5,
  .text li {
    margin-bottom: var(--container-margin-mobile);
  }
}
