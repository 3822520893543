.guarantee_wrapper {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  margin: var(--container-margin) auto;
}
[data-page-type='two-column'] .guarantee_wrapper {
  width: 100%;
  justify-content: center;
}
[data-page-type='two-column'] .guarantee_wrapper .badge_wrapper {
  margin: 0;
  padding-right: var(--container-padding);
}
.badge_wrapper {
  display: flex;
  margin-right: var(--container-margin);
}
.text_wrapper {
  flex: 0 0 85%;
}
.text_wrapper p {
  font-size: var(--md);
}
.divider {
  height: 1px;
  background-color: var(--divider-color);
  width: 100%;
}
@media screen and (max-width: 980px) {
  .guarantee_wrapper {
    width: 100%;
  }
  .text_wrapper {
    flex: 0 0 80%;
  }
  [data-page-type='two-column'] .guarantee_wrapper .text_wrapper p {
    font-size: var(--sm);
  }
  .badge_wrapper {
    width: 70px;
  }
}
