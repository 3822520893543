.embla {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.embla__viewport {
  display: flex;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.embla__slide {
  position: relative;
  flex: 0 0 358px;
  margin-right: 28px;
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 20px;
}

@media screen and (max-width: 980px) {
  .embla__dots {
    padding-top: 13px;
  }
}
