.container {
  background-color: var(--background-color);
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.heading {
  color: var(--heading-color);
  font-family: var(--heading-font-family);
  font-size: var(--xl6);
  text-align: center;
}
.rating {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--xl4);
  font-weight: var(--text-weight-bold);
}
.star_wrapper {
  font-size: var(--xl);
  margin-left: 12px;
}
.subheading {
  color: var(--heading-color);
  font-family: var(--heading-font-family);
  font-size: var(--xl2);
  font-weight: var(--heading-weight-black);
  text-align: center;
  margin-bottom: var(--container-margin);
}
.reviews {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: var(--container-margin);
}
.show_more_btn {
  width: 30%;
  height: 65px;
  margin: 0 auto;
  background-color: var(--button-color);
  border: 1px solid var(--button-border-color);
  border-radius: 5px;
  color: var(--button-text-color);
  font-weight: var(--text-weight-bold);
  font-size: var(--lg);
  padding: var(--container-padding) 0;
  cursor: pointer;
}
.show_more_btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}
@media screen and (max-width: 980px) {
  .heading {
    font-size: var(--xl3);
  }
  .rating {
    font-size: var(--xl3);
  }
  .star_wrapper {
    font-size: var(--lg);
  }
  .show_more_btn {
    margin: 0;
    font-size: var(--lg);
    width: 100%;
  }
  .subheading {
    font-size: var(--xl);
  }
}
