.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  color: var(--text-color);
}
.item h4 {
  margin: 0 !important;
}
.star {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.star_wrapper {
  font-size: var(--xl);
  margin-left: 40px;
  margin-right: 12px;
}
@media screen and (max-width: 980px) {
  .star {
    flex: 0 0 40%;
  }
  .item h4 {
    font-size: var(--lg);
  }
  .star_wrapper {
    font-size: var(--md);
    margin-left: 10px;
  }
}
