.container {
  background-color: var(--background-color);
  padding: 20px 30px;
}
.wrapper {
  max-width: 770px;
  margin: 0 auto;
}
.header h1,
.header h3 {
  width: 100%;
  text-align: center;
}
.header h1 {
  margin-bottom: 16px;
  font-size: var(--xl5);
  color: var(--title-color);
}
.header h3 {
  margin-bottom: 32px;
  color: var(--subtitle-color);
  font-size: var(--xl3);
}
.two_column {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.img_wrapper {
  height: 100%;
  max-width: 320px;
}
.text_wrapper p,
.text_wrapper li {
  color: var(--text-color);
}
.text_wrapper p {
  margin-bottom: 20px;
  font-size: var(--md);
}
.text_wrapper h1 {
  font-size: var(--lg);
}
.text_wrapper li {
  font-size: var(--sm);
}
.text_wrapper ul {
  list-style-type: none !important;
  padding-left: 0 !important;
}
.btn {
  max-width: 80%;
  margin: 30px auto 5px auto;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-border-color);
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
  border-radius: var(--border-radius);
}
.btn:hover {
  background-color: var(--button-on-hover-color);
  color: var(--button-on-hover-text-color);
  border: 1px solid var(--button-on-hover-border-color);
}
.guarantee_text {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 980px) {
  .container {
    padding: var(--container-padding);
  }
  .two_column {
    height: auto;
    flex-direction: column;
  }
  .btn {
    width: 100%;
    max-width: none;
  }
  .img_wrapper {
    max-width: 100%;
  }
  .image_wrapper img {
    object-fit: contain;
    width: 100% !important;
    height: auto !important;
  }
}
