.container {
  margin-bottom: var(--container-margin);
}
.reviews_wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--container-margin);
}
.divider {
  color: var(--text-color-dark);
  font-size: var(--sm);
  font-weight: var(--text-weight-bold);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: var(--container-margin);
}
.divider_with_text::before,
.divider_with_text::after {
  content: '';
  background-color: var(--divider-color);
  height: 1px;
  flex: 1 0 2em;
}
.divider_with_text::before {
  margin-right: 10px;
}
.divider_with_text::after {
  margin-left: 10px;
}
.divider_without_text {
  height: 1px;
  background-color: var(--divider-color);
}
@media screen and (max-width: 980px) {
  .container {
    margin: 0 auto;
    width: 100%;
  }
  .reviews_wrapper {
    gap: var(--container-margin-mobile);
  }
  .divider {
    width: 90%;
    margin: 0 auto;
    margin-bottom: var(--container-margin-mobile);
  }
}
